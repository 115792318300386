import React from "react";
/**
 * @param  {()=>{}} showLoader
 * @param  {()=>{}}} showSnackBar
    -- Used to show user messages on success or failed transaction
    accepts a object with 2 values 'type: success || error || warning & 'message: any string'
    -- sample, this.context.showSnackBar({type: 'error', message: 'Data load failed'})
 */
export const AppContext = React.createContext({
  showLoader: () => {},
  showSnackBar: () => {},
  setLocaleData: () => {}
});
