import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Landing from "pages/LandingPage";
import Home from "pages/Home";
import ErrorComponent from "pages/ErrorComponent";
import AuthErr from "components/common/AuthErr";
import AccessDenied from "components/accessDenied/index";

export default (
  <Switch>
    <Redirect push exact from="/index.html" to="/home" />
    <Route exact path="/" key="Landing" component={Landing} />
    <Route exact path="/home" key="home" component={Home} />
    <Route exact path="/accessDenied" component={AccessDenied} />
    <Route
      exact
      path="/pagenotfound" // path="/noenrollment"
      key="/pagenotfound" //key="/noenrollment" // adminnoenrollment
      component={ErrorComponent}
    />
    <Route exact path="/authErr" key="/authErr" component={AuthErr} />
  </Switch>
);
