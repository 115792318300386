import React, { Component } from "react";
import PropTypes from "prop-types";

export class ErrorCatchComponent extends Component {
  static propTypes = {
    children: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      showError: false
    };
  }

  handleDismiss = () => {
    this.setState({
      showError: false
    });
  };

  componentDidCatch() {
    this.setState(
      {
        showError: true
      },
      () => {
        setTimeout(() => {
          this.setState({
            showError: false
          });
        }, 10000);
      }
    );
  }

  render() {
    const {
      state: { showError },
      props: { children }
    } = this;
    return (
      <div>
        {showError && (
          <div className="errorMsgHome error icon message systemError">
            <i
              aria-hidden="true"
              className="icon icon-after icon-reset"
              onClick={this.handleDismiss}
            />
            <i
              aria-hidden="true"
              className="icon icon-after icon-resetcircle"
            />
            <div className="content">
              <div className="header">Something Went Wrong</div>
              <p>
                Sorry for the inconvenience, Unable to process your request.
                Kindly check after sometime.
              </p>
            </div>
          </div>
        )}
        {children}
      </div>
    );
  }
}

export default ErrorCatchComponent;
