// This component is for allowing non React.Component modules to access the context.intl object		

  // Neccessary because of current limitations of react-intl funcitons (^1.2.5 as of this comment)		
 // being limited to render components only, this will allow import and use of intl functions in		
 // redux portions of the app.		

 import { intlShape } from 'react-intl';		

 let INTL;		

 // Component inherits the conexts from its parent (which should be IntlProvider)		
const IntlGlobalProvider = (props, context) => {		
  INTL = context.intl;		
  return props.children;		
};		

 // prototype for react-intl module we need for context		
IntlGlobalProvider.contextTypes = {		
  intl: intlShape.isRequired		
};		

 let instance;		

 // this is a singleton class		
class IntlTranslator {		
  constructor() {		
    // create only one instance of this component, if one exists return it as the constructor		
    if (!instance) {		
      instance = this;		
    }		
    return instance;		
  }		

   // mirror behavior of formatMessage component using singleton class method		
  formatMessage(message, values) {		
    return INTL.formatMessage(message, values);		
  }		
  // TODO : find the approprate content property for formatHTMLMEssage		
  // formatHTMLMessage(message, values) {		
  //   return INTL.formatHTMLMessage(message, values);		
  // }		

   // other react-intl funtctions should be mapped here if use is desired outside of React.Component		
}		


 // importable intl instance that will reveal the context of IntlGlobalProvider		
export const intl = new IntlTranslator();		

 // provider component which should be used ONCE in the app as the root for pulling the context		
export default IntlGlobalProvider;