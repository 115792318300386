export const APP_CONSTANT = {
  LOCALE: {
    ENGLISH_LOCALE_CODE: "en-US"
  },
  APPLICATON_TYPE: {
    INTERNAL: "internal",
    EXTERNAL: "external"
  },
  USER_TYPE: {
    SYSTEM_ADMIN: "SystemAdmin",
    SUPER_ADMIN: "SuperAdmin",
    ETHICS_ADMIN: "EthicsAdmin",
    AUTHOR: "Author",
    APPROVER: "Approver",
    TRANSLATIONADMIN: "TranslationAdmin"
  },
  USER_ROLE: [
    "QuestionnaireManagement",
    "EthicsAdmin",
    "Reviewer",
    "ReviewAdmin"
  ],
  LANDING_PAGE_URL: {
    PROD_BASE_URL: "https://forms.apple.com",
    UAT_IDMS_URL: "https://appleid.apple.com",
    PROD_IDMS_URL: "https://appleid-ut.corp.apple.com",
    DEV_REDIRECT_URL:"submission/applications/General"
  },
  APPLICATION_NAME: "Form Manager"
};
