import React from "react";
import PropTypes from "prop-types";

const Textfield = props => {
  const {
    id,
    label,
    className,
    ariaLabel,
    defaultValue,
    errorLabel,
    isError,
    ...rest
  } = props;
  return (
    <div className={`form-textbox ${className} ${isError ? "is-error" : ""}`}>
      <input
        {...rest}
        className={`form-textbox-input`}
        defaultValue={defaultValue}
        aria-label={ariaLabel}
        id={id}
      />
      <span className="form-textbox-label" aria-hidden="true">
        {label}
      </span>
      {isError && (
        <div className="form-message-wrapper" id={`form-message-${id}`}>
          <span className="visuallyhidden">Error:</span>
          <span className="form-message">{errorLabel}</span>
        </div>
      )}
    </div>
  );
};

Textfield.propTypes = {
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  isError: PropTypes.string,
  errorLabel: PropTypes.string,
  id: PropTypes.string,
  ariaLabel: PropTypes.string
};

Textfield.defaultProps = {
  type: "text"
};

export default Textfield;
