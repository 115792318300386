import React from "react";
import PropTypes from "prop-types";

const Radio = props => {
  const {
    isError,
    className,
    heading,
    options,
    showHeading,
    onChange,
    id,
    labelError,
    name,
    ariaLabel
  } = props;
  const keyGen = key => `key-${key}`;
  return (
    <div
      className={`${className} ${
        isError ? "is-error" : ""
      } pattern-radiobutton`}
    >
      {showHeading && <p className="form-group-label">{heading}</p>}
      <div className="pattern-radiobutton-group">
        {options &&
          options.length > 0 &&
          options.map((option, i) => (
            <div key={keyGen(i)} className="form-radiobutton">
              <input
                {...props}
                type="radio"
                name={name}
                id={option.id}
                {...(option.checked && { defaultChecked: true })}
                {...(option.disabled && { disabled: true })}
                className={`form-radiobutton-input ${className}`}
                value={option.value}
                onChange={() => onChange(option)}
                aria-label={ariaLabel}
              />
              <label className="form-label" htmlFor={option.id}>
                <span className="form-radiobutton-indicator" />
                {option.label}
              </label>
            </div>
          ))}
      </div>
      {isError && (
        <div className="form-message-wrapper" id={`form-message-${id}`}>
          <span className="visuallyhidden">Error:</span>
          <span className="form-message">{labelError}</span>
        </div>
      )}
    </div>
  );
};

Radio.propTypes = {
  heading: PropTypes.string,
  name: PropTypes.string,
  showHeading: PropTypes.bool,
  options: PropTypes.array,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  isError: PropTypes.bool,
  labelError: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func
};

Radio.defaultProps = {
  heading: "",
  name: "radioName",
  showHeading: false
};

export default Radio;
