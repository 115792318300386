import React from "react";
import Proptypes from "prop-types";

const Title = props => {
  const { titleText, level, className, type, ...rest } = props;

  let headingType = type
    ? `typography-headline-${type}`
    : "typography-headline";

  const Heading = `h${level}`;

  return (
    <Heading {...rest} className={`${headingType} ${className}`}>
      {titleText}
    </Heading>
  );
};

Title.propTypes = {
  titleText: Proptypes.object,
  level: Proptypes.string,
  className: Proptypes.string,
  type: Proptypes.string
};
Title.defaultProps = {
  level: "2",
  type: "typography-headline",
  titleText: "",
  className: ""
};

export default Title;
