import React, { Component } from "react";
import PropTypes from "prop-types";

class TabNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentItem: props.selectedItem
    };
  }
  handleSelectedTab = currentTab => {
    const { handleTabChange } = this.props;
    this.setState({
      currentItem: currentTab.label
    });
    handleTabChange(currentTab);
  };
  
  render() {
    const {
      props: { tabItems, className, linkItemCallBack },
      state: { currentItem }
    } = this;
    return (
      <>
        <div className={`tabnav ${className}`}>
          <ul className="tabnav-items">
            {!linkItemCallBack &&
              tabItems &&
              tabItems.map(item => {
                return (
                  <li
                    className="tabnav-item"
                    key={item.label}
                    onClick={() => this.handleSelectedTab(item)}
                  >
                    <a
                      href={`#${item.path}`}
                      className={`tabnav-link ${
                        item.label === currentItem ? "current" : ""
                      }`}
                    >
                      {item.icon && (
                        <i aria-hidden="true" className={item.icon} />
                      )}
                      <span className="tabnav-label">{item.label}</span>
                    </a>
                  </li>
                );
              })}
          </ul>
          {linkItemCallBack && linkItemCallBack()}
          <div className="tabnav-paddles">
            <button
              className="tabnav-paddle tabnav-paddle-left"
              aria-hidden="true"
              disabled
            />
            <button
              className="tabnav-paddle tabnav-paddle-right"
              aria-hidden="true"
              disabled
            />
          </div>
        </div>
      </>
    );
  }
}

TabNavigation.propTypes = {
  tabItems: PropTypes.array,
  handleTabChange: PropTypes.func,
  className: PropTypes.string,
  selectedItem: PropTypes.string,
  linkItemCallBack: PropTypes.func
};
TabNavigation.defaultProps = {
  tabItems: [],
  handleTabChange: () => {}
};

export default TabNavigation;
