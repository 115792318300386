import apiInstance from "./fetchService";

/*
    API Call for Check Users
*/
async function checkUser() {
  const checkUserApi = await apiInstance.get('/common/checkUser');
  return checkUserApi;
}

/*
    API Call for Login Sections
*/

async function getHomeLoginSection() {
  return apiInstance
    .get(`/common/loginSection`)
    .then(response => {
      return response;
    })
    .catch(error => {
      console.error(error.error);
    });
}

async function getLocaleData(moduleName, locale) {
  return apiInstance.get(`/submission/moduleLocaleDetails?moduleName=${moduleName}&locale=${locale}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      console.error(error.error);
    });
}

async function getAppType() {
  return apiInstance.get(`/common/applicationConfig`)
    .then(response => {
      return response;
    })
    .catch(error => {
      console.error(error.error);
    });
}
async function getUserAccess() {
  return apiInstance.get(`/admin/userAccess`)
    .then(response => {
      return response;
    })
    .catch(error => {
      console.error(error.error);
    });
}

export { checkUser, getHomeLoginSection, getLocaleData, getAppType, getUserAccess }