import React, { Component } from "react";
import PropTypes from "prop-types";
import blueExclamation from "assets/images/blueExclamation.svg";
import redExclamation from "assets/images/redExclamation.svg";
import greenTickCircle from "assets/images/greenTickCircle.svg";
import yellowExclamation from "assets/images/yellowExclamation.svg";
import Accessibility from "messages/Accessibility";
/**
 *
 * @param {boolean}
 * @param {boolean}
 */

class SnackBar extends Component {
  constructor(props) {
    super(props);
    this.snackIconType = { success: "checkcircle", error: "resetcircle" };
  }
  render() {
    const {
      props: { type, content }
    } = this;
    const iconType = {
      caution: blueExclamation,
      error: redExclamation,
      success: greenTickCircle,
      warning: yellowExclamation
    };
    return (
      <div className="snackMessageWrapper">
        <div className={`icon message ${type}`}>
          {iconType[type] && (
            <img
              className="iconImg"
              src={iconType[type]}
              aria-label={
                Accessibility["Zilker.submission.formElements.imageField"]
              }
              alt=""
            />
          )}
          <i
            aria-hidden="true"
            className={`icon icon-after icon-${this.snackIconType[type]}`}
          />
          <div className="content">
            <p>{content}</p>
          </div>
        </div>
      </div>
    );
  }
}

SnackBar.propTypes = {
  type: PropTypes.string,
  content: PropTypes.string
};
SnackBar.defaultProps = {
  type: "success"
};

export default SnackBar;
