import React, { useState, useContext, useLayoutEffect } from "react";
import { FormattedHTMLMessage } from "react-intl";
import { AppContext } from "context/AppContext";
import "./Landingpage.scss";
import { getLocaleData, getAppType } from "services/homeService";
import { setLocale, getUserData } from "services/utils";
import { APP_CONSTANT } from "Constants/appConstants";
import PropTypes from "prop-types";
import LoaderUI from "components/LoaderUI/LoaderUI";
import Accessibility from "messages/Accessibility";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";

const Landing = () => {
  const [loading, setLoading] = useState(true);
  const [baseUrl, setBaseUrl] = useState("");
  const [app, setApp] = useState("");
  const [idmsUrl, setIdmsUrl] = useState("");
  const appContext = useContext(AppContext);
  const { LANDING_PAGE_URL, APPLICATION_NAME } = APP_CONSTANT;

  useLayoutEffect(() => {
    const api = getAppType();
    api
      .then(response => {
        const appType = response.data.type; // TODO: Check data available in response object
        setBaseUrl(response.data.baseUrl);
        let idmsUrl =
          response.data.baseUrl === LANDING_PAGE_URL.PROD_BASE_URL
            ? LANDING_PAGE_URL.UAT_IDMS_URL
            : LANDING_PAGE_URL.PROD_IDMS_URL;
        setIdmsUrl(idmsUrl);
        if (appType === "internal") {
          window.location.href = "/home";
        }
        setApp(appType);

        checkUserApi();
      })
      .catch(err => {
        console.error(err);
        setApp("external");
        checkUserApi();
      });
  }, []); // eslint-disable-next-line

  const checkUserApi = () => {
    setLoading(true);
    const userData = getUserData();

    if (userData !== null) {
      window.location.href = "/home"; //`https://appleid-ut.corp.apple.com/account?appId=173052&returnUrl=${baseUrl}%2Fhome`;
    } else {
      document.title = `Sign in - ${APPLICATION_NAME} `;
      let locale = window
        ? setLocale(window.navigator.language)
        : APP_CONSTANT.ENGLISH_LOCALE_CODE;
      getLocaleData("submission.landing", locale)
        .then(response => {
          appContext.setLocaleData(response.data.localeDetails, false);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const logInPage = () => {
    window.location.href = `${baseUrl}/${LANDING_PAGE_URL.DEV_REDIRECT_URL}`
  };
  return (
    <div>
      {app === "external" ? (
        <div className="landing-wrapper">
          {loading ? (
            <LoaderUI /> //added
          ) : (
            <>
              <div className="has-content-bg">
                <main className="main" role="main">
                  <>
                    <div className="extended-width-container">
                      <div className="content-container">
                        <h1 className="headline">
                          <FormattedHTMLMessage id="zilker.landing.heading" />
                        </h1>
                        <p className="intro">
                          <FormattedHTMLMessage id="zilker.landing.signInToAccessForms" />
                        </p>
                        <a
                          href="# "
                          onClick={logInPage}
                          className="button button-elevated mobile-button-reduced"
                          aria-label={
                            Accessibility[
                              "Zilker.container.wrapper1.signInButton"
                            ]
                          }
                        >
                          <FormattedHTMLMessage id="zilker.landing.signInButton" />
                        </a>
                        <p className="body-text">
                          {
                            <FormattedHTMLMessage id="zilker.landing.needAppleAccount" />
                          }
                        </p>
                        <a
                          href={`${idmsUrl}/account?appId=173052&returnUrl=${baseUrl}%2Fhome`}
                          className="body-text icon icon-external icon-after create-cta"
                          aria-label={
                            Accessibility[
                              "Zilker.container.wrapper3.createAccount"
                            ]
                          }
                        >
                          <FormattedHTMLMessage id="zilker.landing.createOneNow" />
                        </a>
                      </div>
                    </div>
                  </>
                </main>
              </div>
            </>
          )}
        </div>
      ) : (
        <LoaderUI />
      )}
    </div>
  );
};

Landing.propTypes = {
  history: PropTypes.object
};

Landing.contextType = AppContext;
export default withRouter(injectIntl(Landing));
