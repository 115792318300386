/**
 * Shared messages
 * @module messages
 * @type {{id: string, defaultMessage: string}}
 */

export const serverErrorMessage = {
	serverError: {
		id: 'cc.shared.sorry.body',
		defaultMessage:
			'<span class="default-message">We are unable to process your request at this moment. Please try again</span>', // eslint-disable-line max-len
	},
};

export const serverErrorMessages = {
	id: 'cc.shared.sorry.body',
	defaultMessage:
		'<span class="default-message">Sorry, but we are unable to process your request. Please try again later or <a href="https://support.apple.com/en-us/HT201232" target="_blank">contact us</a>.</span>', // eslint-disable-line max-len
};

export const localNav = {
	appname: {
		id: 'cc.mysupport.header',
		defaultMessage: 'App Name',
		description: 'Title for the unauthenticated page',
	},
	countryselector: {
		id: 'cc.country.selector.header',
		defaultMessage: 'Choose your country or region',
		description: 'Title for the countryselector page',
	},
	signin: {
		id: 'cc.shared.signin.mysupport',
		defaultMessage: '<span class="default-message">Sign In</span>',
	},
	user: {
		id: 'cc.shared.signin.hello',
		defaultMessage: '<span class="default-message">Hello, {firstName} {lastName}</span>',
		description: 'User greeting',
	},
	signout: {
		id: 'cc.shared.signin.signout.link',
		defaultMessage: '<span class="default-message">Sign out</span>',
		description: 'Sign out link text',
	},
};

const mySupportTitle = {
	id: 'cc.mysupport.header',
	defaultMessage: 'My Support',
	description: 'Title for the authenticated page',
};
export const footer = () => ({
	mySupportTitle,
	footerCountryName: {
		id: 'cc.footer.country',
		defaultMessage: 'United States',
	},
	footerLinkHome: {
		id: 'cc.footer.link.home',
		defaultMessage: `<a href="http://www.apple.com/" class="home"><span aria-hidden="true"></span>
    <span class="breadcrumbs-home-label">Home</span></a>`,
	},
	footerLinkSupport: {
		id: 'cc.footer.link.support',
		defaultMessage: '<a href="http://www.apple.com/support/">Support</a>',
	},
	footerLinkCheckCoverage: {
		id: 'cc.footer.link.checkcoverage',
		defaultMessage: 'Check Coverage',
	},
	footerShop: {
		id: 'cc.footer.shop',
		defaultMessage: `More ways to shop: Visit an <a href="http://www.apple.com/retail/">Apple Store</a>
    , call 1-800-MY-APPLE, or <a href="https://locate.apple.com/">find a reseller</a>.`,
	},
	footerLinkAbout: {
		id: 'cc.footer.link.about',
		defaultMessage: '<a href="http://www.apple.com/about/" class="first">Apple Info</a>',
	},
	footerLinkSecurity: {
		id: `cc.footer.link.security`,
		defaultMessage: '<span class="default-message"></span>',
	},
	footerLinkSitemap: {
		id: 'cc.footer.link.sitemap',
		defaultMessage: '<a href="http://www.apple.com/sitemap/">Site Map</a>',
		description: 'test',
	},
	footerLinkContact: {
		id: 'cc.footer.link.contact',
		defaultMessage: '<a href="http://www.apple.com/contact/">Contact Apple</a>',
	},
	footerCopyright: {
		id: 'cc.footer.copyright',
		defaultMessage: '<p>Copyright © 2019 Apple Inc. All rights reserved.</p>',
	},
	footerLinkLegal: {
		id: 'cc.footer.link.legal',
		defaultMessage: '<a href="http://www.apple.com/legal/" class="first">Terms of Use</a>',
	},
	footerLinkPrivacy: {
		id: 'cc.footer.link.privacy',
		defaultMessage: '<a href="http://www.apple.com/privacy/privacy-policy/">Privacy Policy</a>',
	},
	footerLinkCookies: {
		id: `cc.footer.link.cookies`,
		defaultMessage: '<span class="default-message"></span>',
	},
	footerLinkSalesAndRefunds: {
		id: 'cc.footer.link.salespolicies',
		defaultMessage: '<a href="http://www.apple.com/shop/goto/help/sales_refunds/">Sales and Refunds</a>',
	},
	footerCountryPickerLabel: {
		id: 'cc.footer.link.countryselector.label',
		defaultMessage: 'Choose your country or region.',
	},
});
export const serverErrorTitle = {
	id: 'cc.shared.sorry.header',
	defaultMessage: '<span class="default-message">We’re Sorry</span>',
};

export const landing = {
	globalCommunityTitle: {
		id: 'cc.landing.globalCommunityTitle',
		defaultMessage: 'Global community of experts <br>in the field of education',
	},
	globalCommunityDesc: {
		id: 'cc.landing.globalCommunityDesc',
		defaultMessage:
			'Zilker is a global community of leading people in <br/>the field of innovative teaching. Would you like to <br/>be a part of it?',
	},
	ethicsSupervisorTitle: {
		id: 'cc.landing.ethicsSupervisorTitle',
		defaultMessage: 'Are you Ethics Officer or <br> Supervisor?',
	},
	ethicsSupervisorDesc: {
		id: 'cc.landing.ethicsSupervisorDesc',
		defaultMessage:
			'If you are an ethics officer or supervisor of an <br>ADE member and are responding to a request for <br>reviewing ethics compliance forms, please use <br>the "Sign In" link to log in with a valid Apple ID or <br>use the link to create a new one.',
	},
	reviewTitle: {
		id: 'cc.landing.reviewTitle',
		defaultMessage: 'Are you an Application <br/> Reviewer?',
	},
	reviewDesc: {
		id: 'cc.landing.reviewDesc',
		defaultMessage:
			'If you are a reviewer of Zilker application <br/> submission forms and are responding to a <br/> request for reviewing applicant forms, please use <br/> the "Sign In" link to log in with a valid Apple ID or <br/> use the link to create a new one. ',
	},
	continueText: {
		id: 'cc.shared.continueTxt',
		defaultMessage: 'Press this button to make the API call',
	},
	createAccountText: {
		id: 'cc.shared.createAccountText',
		defaultMessage: 'Create one now.',
	},
	signIn: {
		id: 'cc.shared.signIn',
		defaultMessage: 'Sign In',
	},
	needAppleAccount: {
		id: 'cc.shared.needAppleAccount',
		defaultMessage: 'Need an apple account',
	},
	createone: {
		id: 'cc.shared.createOne',
		defaultMessage: 'Create one now',
	},
};

export const application = {
	applicationGlobalCommunity: {
		id: 'cc.application.globalCommunity',
		defaultMessage:
			'Zilker is a global commmunity of leading people in the field of innovative teaching by leveraging the advanced technology in and out of the classroom.',
	},
	applicationDescription: {
		id: 'cc.application.applicationDescription',
		defaultMessage:
			'By engaging leaners in powerful new ways, they foster unique learning environments that inspire creativity and collaboration, and they share their <br/> knowledge with educator around the globe so that others may benefit',
	},
	applicationFourSection: {
		id: 'cc.application.applicationFourSection',
		defaultMessage: 'The Application consists of four sections.',
	},
	applicationSubmission: {
		id: 'cc.application.applicationSubmission',
		defaultMessage:
			'You may return to this site as often as needed to complete each section or make edits before <br/> submitting your final application',
	},
	applicationCalenderTitle: {
		id: 'cc.application.applicationCalenderTitle',
		defaultMessage: 'Complete on or before <br /> May 31 2020',
	},
	applicationCalenderDesc: {
		id: 'cc.application.applicationCalenderDesc',
		defaultMessage:
			'Click the "Submit when complete" button after all sections of your application are completed to submit your application for review',
	},
	applicationNotificationTitle: {
		id: 'cc.application.applicationNotificationTitle',
		defaultMessage: 'Look for notification from Apple <br /> For further updates',
	},
	applicationNotificationDesc: {
		id: 'cc.application.applicationNotificationDesc',
		defaultMessage:
			'After submitting your application, program team will contact you via email if your application is missing information or there are technical issues with your submitted details. If contacted, you must return to this site to review requested changes and resubmit your application promptly',
	},
	applicationEmailTitle: {
		id: 'cc.application.applicationEmailTitle',
		defaultMessage: 'Contact Program Administrators for <br /> any technical help',
	},
	applicationEmailDesc: {
		id: 'cc.application.applicationEmailDesc',
		defaultMessage:
			'All applicants will be notified via email when final seelections have been made. if you have technical issues completing or submitting your applications, please email us at:',
	},
	applicationSubmissionNotes: {
		id: 'cc.application.applicationSubmissionNotes',
		defaultMessage: 'Note: You must complete all the sections and pages to submit the application',
	},
	applicationSubmissionButton: {
		id: 'cc.application.applicationSubmissionButton',
		defaultMessage: 'Submit When Complete',
	},
};

export const Submission = {
	dumbCompLinkText: {
		id: 'cc.shared.dumbCompLinkTxt',
		defaultMessage: 'Go to another page',
	},
	continueText: {
		id: 'cc.shared.continueTxt',
		defaultMessage: 'Press this button to make the API call',
	},
	submissionCountText: {
		id: 'cc.shared.countTxt',
		defaultMessage: ' Submissions available',
	},
};

export const Applicants = {
	homeTitle: {
		id: 'cc.landing.homeTitle',
		defaultMessage: 'Welcome to Zilker Community',
	},
	homeSubHeading: {
		id: 'cc.landing.homeSubHeading',
		defaultMessage: 'Education, Innovation and Beyond',
	},
	homeWouldLiketoContinue: {
		id: 'cc.landing.homeWouldLiketoContinue',
		defaultMessage: 'How would you like to continue?',
	},
	continue: {
		id: 'cc.landing.continue',
		defaultMessage: 'Continue',
	},
	errorMessage: {
		id: 'cc.error.errorSubHeading',
		defaultMessage:
			'<span class="default-message">Sorry, but we are unable to process your request.<br>Please try again later or <a href="https://support.apple.com/en-us/HT201232" target="_blank">contact us</a>.</span>',
	},
};
export const errorMsg = {
	errorTitle: {
		id: 'cc.error.errorTitle',
		defaultMessage: 'Dear User Appleseed',
	},
	errorMessage: {
		id: 'cc.error.errorSubHeading',
		defaultMessage:
			'Thank you for showing interest to be part of Zilker Programme. Currently this is invite only based application acceptance.<br> So, Please check for the programme invitation received on your mail or watch this space for more updates',
	},
};
