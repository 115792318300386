import React from "react";
import PropTypes from "prop-types";

const Button = props => {
  const {
    children,
    onClick,
    type,
    label,
    disabled,
    className,
    styles,
    ...rest
  } = props;
  let buttonType = type ? type.map(item => `button-${item}`).join(" ") : "";
  return (
    <button
      className={`button ${buttonType} ${className}`}
      disabled={disabled}
      onClick={onClick}
      style={{ ...styles }}
      {...rest}
    >
      {label || children}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ]).isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  styles: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  type: PropTypes.array
};

Button.defaultProps = {
  onClick: () => {},
  styles: "",
  className: "",
  disabled: false
};

export default Button;
