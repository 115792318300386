import React from "react";
import { ActionLink } from "components/tembusuLocal";
import PropTypes from "prop-types";
import { FormattedHTMLMessage } from "react-intl";
import Accessibility from "messages/Accessibility";

const RoleCard = ({
  icon,
  hrefLink,
  localeDescription,
  localeTitle,
  accessHeading,
  accessDescription,
  accessContinue,
  onPopupModelFlag,
  getLoaderOnContinue
}) => {
  return (
    <div
      className="rolecard-wrapper"
      aria-labelledby={`${accessHeading} ${accessDescription} ${accessContinue}`}
    >
      <i aria-hidden="true" className={icon} />
      <h1 id={`${accessHeading}`} aria-label={Accessibility[accessHeading]}>
        {<FormattedHTMLMessage id={localeTitle} />}
      </h1>
      <p
        role="heading"
        id={`${accessDescription}`}
        aria-label={Accessibility[accessDescription]}
        className="desc"
      >
        {<FormattedHTMLMessage id={localeDescription} />}
      </p>

      <ActionLink
        role="link"
        id={`${accessContinue}`}
        className="more"
        linkContent={<FormattedHTMLMessage id="zilker.home.continue" />}
        hrefLink={hrefLink}
        onClick={
          !hrefLink
            ? () => onPopupModelFlag(true)
            : () => {
                getLoaderOnContinue();
              }
        }
        aria-label={Accessibility[accessContinue]}
      />
      {/* :()} */}
    </div>
  );
};

RoleCard.propTypes = {
  data: PropTypes.object,
  icon: PropTypes.string,
  title: PropTypes.string,
  accessHeading: PropTypes.string,
  accessDescription: PropTypes.string,
  accessContinue: PropTypes.string,
  description: PropTypes.string,
  hrefLink: PropTypes.string,
  localeTitle: PropTypes.string,
  localeDescription: PropTypes.string,
  getLoaderOnContinue: PropTypes.func,
  onPopupModelFlag: PropTypes.func
};

export default RoleCard;
