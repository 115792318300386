import React, { useEffect, useContext } from "react";
import { AppContext } from "context/AppContext";

import { FormattedHTMLMessage } from "react-intl";

const AccessDenied = () => {
  const { setLocaleData } = useContext(AppContext);

  useEffect(() => {
    setLocaleData();
  }, []); // eslint-disable-next-line

  return (
    <div className="access-container typography-body">
      <div className="typography-eyebrow-reduced">
        <FormattedHTMLMessage id="zilker.AccessDeniedHeader" />
      </div>
      <>
        <FormattedHTMLMessage id="zilker.AccessDeniedDesc1" />
      </>
      <>
        <FormattedHTMLMessage id="zilker.AccessDeniedDesc2" />
      </>
    </div>
  );
};

export default AccessDenied;
