import React, { useEffect, useState } from "react";
import { FormattedHTMLMessage } from "react-intl";
import { errorMsg as messages } from "messages/messages";
import "./error.scss";
import { Button } from "components/tembusuLocal";
import { APP_CONSTANT } from "mockData/Constants";
import { setNewLineContent, setLocale } from "services/utils";
import apiInstance from "services/fetchService";

const ErrorComponent = () => {
  const [appLabels, setAppLabels] = useState({});

  useEffect(() => {
    let locale = window
      ? setLocale(window.navigator.language)
      : APP_CONSTANT.ENGLISH_LOCALE_CODE;
    apiInstance
      .get(
        `/submission/moduleLocaleDetails?moduleName=Submission.signin&locale=${locale}`
      )
      .then(res => {
        setAppLabels(res.data.localeDetails);
        return res;
      })
      .catch(err => {
        console.error(err, " error");
      });
  }, []);

  const handleClick = () => {
    window.location.href = "/home";
  };
  return (
    <section className="error-wrapper">
      <div className="error-img" />
      <div className="error-content">
        <p className="error-title">
          {appLabels && appLabels[`error.errTitle`] ? (
            setNewLineContent(appLabels[`error.errTitle`])
          ) : (
            <FormattedHTMLMessage {...messages.errorTitle} />
          )}
        </p>
        <p className="error-message">
          {appLabels && appLabels[`error.errMessage`] ? (
            setNewLineContent(appLabels[`error.errMessage`])
          ) : (
            <FormattedHTMLMessage {...messages.errorMessage} />
          )}
        </p>
      </div>
      <div className="err-backbtn">
        <Button
          type={["secondary"]}
          className="back-btn"
          aria-label="Back"
          onClick={handleClick}
        >
          Back
        </Button>
      </div>
    </section>
  );
};
export default ErrorComponent;
