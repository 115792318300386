import React from "react";
import PropTypes from "prop-types";

const ActionLink = props => {
  const { className, linkContent, hrefLink, ...rest } = props;
  return (
    <a {...rest} href={hrefLink} className={className}>
      {linkContent}
    </a>
  );
};
ActionLink.propTypes = {
  linkContent: PropTypes.object,
  className: PropTypes.string,
  hrefLink: PropTypes.string
};
// sample build for accessibility

export default ActionLink;
