import React, { Component } from "react";
import { FormattedHTMLMessage } from "react-intl";
import PropTypes from "prop-types";
import accessDenied from "styles/images/accessdenied.svg";
import { withRouter } from "react-router-dom";
import { getCurrentYear } from "services/utils";
import { Button } from "components/tembusuLocal";

class AuthErr extends Component {
  onLogout = e => {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = "/logout";
  };
  render() {
    return (
      <>
        <div className="error-container">
          <div className="box-container">
            <div className="error-app-container">
              <span className="app-logo">
                <i className="apple icon" />
                <span className="apple-icon">&#63743;</span>
                <FormattedHTMLMessage id="zilker.landing.pageTitle" />
              </span>
            </div>
            <div className="error-content-container">
              <div className="image-container">
                <img
                  src={accessDenied}
                  alt="accessDenied"
                  size="small"
                  className="accessDenied"
                />
              </div>
              <h2 className="access-text">
                <FormattedHTMLMessage id="zilker.error.unauthorised" />
              </h2>
              <p>
                <FormattedHTMLMessage id="zilker.error.permission" />
              </p>
              <Button className="logout-button" onClick={this.onLogout}>
                {" Logout "}
              </Button>
            </div>
            <div className="footer-content">
              <FormattedHTMLMessage
                id="zilker.error.copyrights"
                values={{ getCurrentYear: getCurrentYear() }}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

AuthErr.propTypes = {
  history: PropTypes.object
};

export default withRouter(AuthErr);
