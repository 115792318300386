import React from "react";
import ReactDOM from "react-dom";
import App from "App";

// ========================================================
// Initial state construction
// ========================================================
/**
 * Get the locale information from initial state, and update it as necessary
 * This method assumes that the initial state is stored on the global window.___INITIAL_STATE__ property.
 * It also assumes that the language and country code would be in the initial state object on page.locale.
 * This is default model data for local developement only.
*/


// const backendLocale = `${language}_${countryCode}`;

// // CSRF should be requested on each load of the application
// // To unsubscribe, invoke `store.unsubscribeHistory()` anytime
// store.unsubscribeHistory = history.listen(nextLocation =>{
// 	maybefetchCSRF(store.dispatch, store.getState());
// })
// maybefetchCSRF(store.dispatch, store.getState());

// setupTranslations(store, initialState, languageToLoad ,backendLocale);


ReactDOM.render(
		<App/>,
	document.getElementById("root")
);

//ReactDOM.createRoot(document.getElementById("root")).render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
