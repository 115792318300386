import Axios from "axios";
import { serverErrorMessages } from "messages/messages";
import { intl } from "IntlGlobalProvider";
const DEFAULT_SERVER_ERROR = {
  errorCode: "AGSFATAL",
  errorMessage: serverErrorMessages,
  errorSeverity: "FATAL"
};
/**
 * Handle exceptions.
 * If the API call was successful but returned an error, throw the response error.
 * If the API call failed, throw the exception to show error modal panel.
 * @param {Object} ex
 */
const handleException = ex => {
  if (ex.errorCode) {
    if (!ex.errorMessage) {
      // Throw translated error message outside of React.Component by using our intl singleton
      throw Object.assign({}, ex, {
        errorMessage: intl.formatMessage(serverErrorMessages)
      });
    }
    throw ex;
  }
  throw DEFAULT_SERVER_ERROR;
};
const csrfHeader = "x-apple-csrf-token";
const apiInstance = Axios.create({
  baseURL: "/api/v1/zilkerui"
});

apiInstance.defaults.csrfMethods = ["post", "put", "delete"];
apiInstance.interceptors.request.use(async request => {
  if (apiInstance.defaults.csrfMethods.includes(request.method)) {
    await Axios.get("/csrf")
      .then(res => {
        request.headers[csrfHeader.toLocaleUpperCase()] =
          res.headers[csrfHeader.toLocaleLowerCase()];
      })
      .catch(err => console.error("Error", err));
  }
  return request;
});
apiInstance.interceptors.response.use(
  response => response,
  error => {
    if (error) {
      console.error(
        "Exception while making request to the API in Submission. ",
        error
      );
      handleException(error);
      return Promise.reject(error);
    }
  }
);

export default apiInstance;
