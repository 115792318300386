import React from "react";
import PropTypes from "prop-types";

const ProgressBar = props => {
  const { percentage, className } = props;
  return (
    <figure
      className={`graph graph-sidebar-bar sidebar-graph-3 ${className}`}
      aria-label="sidebar graph example 3"
    >
      <div className="graph-unit sidebar-3-unit-1">
        <div className="graph-data">
          <div className="graph-bar-slot">
            <div style={{ width: `${percentage}%` }} className="graph-bar" />
          </div>
        </div>
      </div>
    </figure>
  );
};

ProgressBar.propTypes = {
  className: PropTypes.string,
  percentage: PropTypes.number
};

ProgressBar.defaultProps = {
  percentage: 0
};

export default ProgressBar;
