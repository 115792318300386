import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { LocalNav } from "components/tembusuLocal";
import PropTypes from "prop-types";
import axios from "axios";

/**
 *
 * @param {boolean} hasMenu - show / hide menu for particular page
 * @param {boolean} showAuthSection - to render auth section - sign out link
 * @param {string} pageTitle - To Show the Page Title
 * @param {string} selectedItem - Current Selected Item
 * @param {object | string} as - NavLink / p / div tag - default is anchor tag
 * @param {string} signOutUrl - singout url link
 */

class MenuComponent extends Component {
  handleLogout = e => {
    e.preventDefault();
    e.stopPropagation();
    axios.get("/logout");
    window.location.href = "/logout";
  };
  getMenu = () => {
    const { location, intl } = this.props;
    const localNav = {
      pageTitle: intl.formatMessage({ id: "zilker.landing.pageTitle" }),
      showAuthSection: location.pathname === "/home" ? true : false,
      classNames: location.pathname === "/" ? "landingNav" : "",
      signOutBtnText: intl.formatMessage({
        id: "zilker.landing.signOutButton"
      }),
      signOutUrl: this.handleLogout
    };
    return localNav;
  };
  render() {
    return (
      <>
        <LocalNav {...this.getMenu()} />
      </>
    );
  }
}

MenuComponent.propTypes = {
  location: PropTypes.object,
  intl: PropTypes.object
};

export default withRouter(injectIntl(MenuComponent));
