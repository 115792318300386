import React from "react";
import "./CoreLayout.scss";
import PropTypes from "prop-types";

const CoreLayout = ({ children }) => {
  return (
    <div className="text-center core-layout">
      <div className="core-layout__viewport">{children}</div>
      <div className="core-layout__spacer" />
    </div>
  );
};

CoreLayout.propTypes = {
  children: PropTypes.object
};

export default CoreLayout;
