import React, { Component } from "react";
import { AppContext } from "context/AppContext";
import { serverErrorMessage as errorMessage } from "messages/messages";
import { Title } from "components/tembusuLocal";
import RoleCard from "components/RoleCard/index";
import { userRolesCardLocale } from "mockData/roleCard";
import {
  checkUser,
  getHomeLoginSection,
  getLocaleData,
  getUserAccess
} from "services/homeService";
import "./home.scss";
import { setLocale, getMessages } from "services/utils";
import { APP_CONSTANT } from "Constants/appConstants";
import LoaderUI from "components/LoaderUI/LoaderUI";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import SnackBar from "components/SnackBar";
import AuthErrorComponent from "components/common/AuthErr";

class Home extends Component {
  constructor(props, context) {
    super(props);
    this.setLocaleData = context.setLocaleData;
    this.errorFocus = React.createRef();

    this.state = {
      userRole: APP_CONSTANT.USER_ROLE,
      rolesCardUserArray: [],
      loader: true,
      userType: [],
      showAuthError: false,
      checkUserRole: [],
      showSnackbar: false,
      snackbarData: {},
      continueLoader: false,
      //errorStatus: false
    };
  }
  transformData = () => {
    const { userRole, userType, checkUserRole } = this.state;
    let result = [];
    let roleForQM = [
      APP_CONSTANT.USER_TYPE.SUPER_ADMIN,
      APP_CONSTANT.USER_TYPE.APPROVER,
      APP_CONSTANT.USER_TYPE.AUTHOR,
      APP_CONSTANT.USER_TYPE.TRANSLATIONADMIN,
      APP_CONSTANT.USER_TYPE.SYSTEM_ADMIN
    ];

    result =
      userRole &&
      userRole.map(item => {
        let userObj = { ...userRolesCardLocale[item] };
        if (item === "QuestionnaireManagement") {
          const systemAdminRole = userType?.includes(
            APP_CONSTANT.USER_TYPE.SYSTEM_ADMIN
          );
          let QRcardVisible = !!userType?.find(item =>
            roleForQM.includes(item)
          );
          if (systemAdminRole) {
            userObj.hrefLink = "/admin/cache";
          } else if (QRcardVisible) {
            if (process.env.NODE_ENV === "development") {
              userObj.hrefLink = "http://localhost:5640/admin/questionbank";
            } else {
              userObj.hrefLink = "/admin/questionbank";
            }
          }
        } else if (item === "EthicsAdmin") {
          if (
            checkUserRole.includes(userObj.role[0]) ||
            checkUserRole.includes(userObj.role[1])
          ) {
            if (process.env.NODE_ENV === "development") {
              userObj.hrefLink =
                "http://localhost.apple.com:5641/ethicsAdmin/ethicsManageSubmissions";
            } else {
              userObj.hrefLink = "/ethicsAdmin/ethicsManageSubmissions";
            }
          }
        }

        if (
          !checkUserRole.includes(userObj.role) &&
          item !== "QuestionnaireManagement" &&
          item !== "EthicsAdmin"
        ) {
          delete userObj.hrefLink;
        }
        return userObj;
      });

    this.setState({ rolesCardUserArray: result.filter(item => item.hrefLink) });
  };

  getLoaderOnContinue = () => {
    this.setState({
      continueLoader: true
    });
  };

  handleHomeSectionCards = () => {
    if (process.env.NODE_ENV === "development") {
      this.setState(
        {
          loader: false
        },
        () => this.transformData()
      );
    } else {
      getHomeLoginSection()
        .then(() => {
          this.setState({ loader: false }, () => {
            this.transformData();

            let locale = window
              ? setLocale(window.navigator.language)
              : APP_CONSTANT.LOCALE.ENGLISH_LOCALE_CODE;
            getLocaleData("submission.home", locale)
              .then(response => {
                this.setLocaleData(response.data.localeDetails);
                this.setState({ loader: false });
              })
              .catch(() => {
                this.setState({ loader: false }, () => {
                  this.snackBar({
                    type: "error",
                    content: getMessages(errorMessage, "serverError")
                  });
                });
              });
          });
        })
        .catch(() => {
          this.setState({ loader: false }, () => {
            this.snackBar({
              type: "error",
              content: getMessages(errorMessage, "serverError")
            });
          });
        });
    }
  };

  async componentDidMount() {
    await getUserAccess()
      .then(response => {
        this.setState({
          userType: response?.data?.role,
          showAuthError: false
        });
      })
      .catch(error => {
        console.error(error.error);
      });
    let currentUserDetail = null; //getUserData();
    if (
      currentUserDetail !== null &&
      currentUserDetail.applicationType ===
        APP_CONSTANT.APPLICATON_TYPE.EXTERNAL
    ) {
      window.location.href = "/submission";
    } else if (process.env.NODE_ENV === "development") {
      this.handleHomeSectionCards();
    }
    if (currentUserDetail === null) {
      await checkUser()
        .then(response => {
          const {
            data: { role }
          } = response;
          this.setState(
            {
              checkUserRole: role,
              showAuthError: false,
              loader: false
            },
            () => {
              localStorage.setItem(
                "checkUserResponse",
                JSON.stringify(response.data)
              );
              if (
                response.data.applicationType ===
                APP_CONSTANT.APPLICATON_TYPE.EXTERNAL
              ) {
                window.location.href = "/submission";
              } else {
                this.handleHomeSectionCards();
              }
            }
          );
        })
        .catch(() => {
          this.setState({ loader: false, showAuthError: true });
        });
    }
    if (
      currentUserDetail &&
      currentUserDetail.applicationType !== "" &&
      currentUserDetail.applicationType ===
        APP_CONSTANT.APPLICATON_TYPE.INTERNAL
    ) {
      this.handleHomeSectionCards();
    } else if (
      currentUserDetail &&
      currentUserDetail.applicationType !== "" &&
      currentUserDetail.applicationType ===
        APP_CONSTANT.APPLICATON_TYPE.EXTERNAL
    ) {
      window.location.href = "/submission";
    }
  }
  keyGen = val => {
    return `MyKey-${val}`;
  };
  showSnackBar = async data => {
    await this.setState(
      {
        showSnackbar: true,
        snackbarData: data
      },
      () => {
        setTimeout(
          () =>
            this.setState({
              showSnackbar: false,
              snackbarData: {}
            }),
          10000
        );
      }
    );
  };
  onPopupModelFlag = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    this.showSnackBar({
      type: "caution",
      content: "You don't have permission to access this module "
    });
  };
  render() {
    const {
      rolesCardUserArray,
      loader,
      showAuthError,
      showSnackbar,
      snackbarData,
      continueLoader
    } = this.state;
    if (loader || continueLoader) {
      return <LoaderUI />;
    }
    if (showAuthError) {
      return <AuthErrorComponent />;
    }

    return (
      <div className="applicantsPage" ref={this.errorFocus}>
        {showSnackbar && (
          <SnackBar content={snackbarData.content} type={snackbarData.type} />
        )}
        <div className="cover">
          <div className={showSnackbar ? "headerWithSnackBar" : "headers"}>
            <Title
              level="1"
              // titleText={<FormattedHTMLMessage id="zilker.home.heading" />}
              type={"super"}
              className={"submission_title"}
            />
            <h2 className="coverage-heading">
              {/* {<FormattedHTMLMessage id="zilker.home.subHeading" />} */}
            </h2>
          </div>

          <div
            className={`grid-container ${
              rolesCardUserArray.length === 1 ? "cardsInFlex" : ""
            } ${rolesCardUserArray.length === 3 ? "cardsInCenter" : ""}`}
          >
            {rolesCardUserArray &&
              rolesCardUserArray.length > 0 &&
              rolesCardUserArray.map((item, i) => (
                <div key={this.keyGen(i)} className="grid-item">
                  <RoleCard
                    {...item}
                    onPopupModelFlag={this.onPopupModelFlag}
                    getLoaderOnContinue={this.getLoaderOnContinue}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

Home.contextType = AppContext;
export default withRouter(injectIntl(Home));
