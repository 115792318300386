import React from "react";
import PropTypes from "prop-types";

const Checkbox = props => {
  const {
    className,
    ariaLabel,
    name,
    options,
    onChange,
    errorLabel,
    isError,
    ...rest
  } = props;
  let selected = options
    .map((o, i) => {
      if (o.checked) {
        return i;
      } else {
        return -1;
      }
    })
    .filter(f => f > -1);

  const onSelectCheckbox = i => {
    if (selected.includes(i)) {
      selected = selected.filter(s => s !== i);
    } else {
      selected.push(i);
    }
    const filtered = options.filter((o, index) => selected.includes(index));
    onChange(filtered);
  };

  const keyGen = key => `key-${key}`;

  return (
    <div
      className={`${className} ${isError ? "is-error" : ""} pattern-checkbox`}
    >
      {options &&
        options.length > 0 &&
        options.map((option, i) => (
          <div
            className={`form-checkbox ${className}`}
            key={keyGen(i)}
          >
            <input
              {...rest}
              type="checkbox"
              {...(option.checked && { defaultChecked: true })}
              {...(option.disabled && { disabled: true })}
              onChange={() => onSelectCheckbox(i)}
              value={option.value}
              id={option.id}
              name={name}
              aria-label={ariaLabel}
              className={`form-checkbox-input`}
            />
            <label className="form-label" htmlFor={option.id}>
              <span className="form-checkbox-indicator" />
              {option.label}
            </label>
          </div>
        ))}
      {isError && (
        <div className="form-message-wrapper">
          <span className="visuallyhidden">Error:</span>
          <span className="form-message">{errorLabel}</span>
        </div>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  options: PropTypes.array,
  name: PropTypes.string,
  ariaLabel: PropTypes.string,
  errorLabel: PropTypes.string,
  isError: PropTypes.bool
};

Checkbox.defaultProps = {
  name: "checkboxName"
};

export default Checkbox;
