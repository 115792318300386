const roleCardDetailsInternal = [
  {
    icon: "Ziext-Questionnaire",
    header: "Questionnaire Management",
    Description:
      "I am Author, Approver, Translation Admin or SuperAdmin and i want to manage Questionnaire / Application activities"
  },
  {
    icon: "zi-Ethics-Admin",
    header: "Ethics Admin",
    Description:
      "I am an Ethics admin and want to oversee the Ethics complaince submissions by Ethics members and take necessary actions"
  },
  {
    icon: "zi-Reviewer",
    header: "Reviewer",
    Description: "On Hold"
  },
  {
    icon: "zi-Review-Admin",
    header: "Review Admin",
    Description: "On Hold"
  }
];
const roleCardDetailsExternal = [
  {
    icon: "zi-Zilker-Applicant",
    header: "Zilker Applicant",
    Description:
      "I am new to this aplication / programme and i want to fill / complete the applicantion form"
  },
  {
    icon: "zi-Ethics-Compliance",
    header: "Ethics Complaince",
    Description:
      "I am a member of Zilker Programme and i have been asked to complete the Ethics compliance form"
  },
  {
    icon: "zi-Ethics-Supervisor",
    header: "Ethics Supervisor",
    Description:
      "I am the Ethics supervisor (or equivalent position) for Zilker Programme in our organization and have been asked to complete their ethics form."
  },
  {
    icon: "zi-Ethics-Officer",
    header: "Ethics Officer",
    Description:
      "I am the Ethics officer (or equivalent position) for Zilker Programme in our organization and have been asked to complete their ethics form."
  }
];

const userRolesCard = {
  GeneralApplicant: {
    icon: "zilker-icon zi-Zilker-Applicant",
    description:
      "I am new to this aplication / programme and i want to fill / complete the applicantion form",
    title: "General Applicant"
  },
  EthicsCompliance: {
    icon: "zilker-icon zi-Ethics-Compliance",
    description:
      "I am a member of Zilker Programme and i have been asked to complete the Ethics compliance form",
    title: "Ethics Compliance"
  },
  EthicsSupervisor: {
    icon: "zilker-icon zi-Ethics-Supervisor",
    description:
      "I am the Ethics supervisor (or equivalent position) for Zilker Programme in our organization and have been asked to complete their ethics form.",
    title: "Ethics Supervisor"
  },
  EthicsOfficer: {
    icon: "zilker-icon zi-Ethics-Officer",
    description:
      "I am the Ethics officer (or equivalent position) for Zilker Programme in our organization and have been asked to complete their ethics form.",
    title: "Ethics Officer"
  }
};
const userRolesCardLocale = {
  GeneralApplicant: {
    icon: "zilker-icon zi-Zilker-Applicant",
    localeDescription: "siginin.generalApplicantDescription",
    description:
      "I am new to this aplication / programme and i want to fill / complete the applicantion form",
    localeTitle: "siginin.generalApplicantTitle",
    title: "General Applicant"
  },
  EthicsCompliance: {
    icon: "zilker-icon zi-Ethics-Compliance",
    localeDescription: "siginin.ethicsCompliancetDescription",
    description:
      "I am a member of Zilker Programme and i have been asked to complete the Ethics compliance form",
    localeTitle: "signin.ethicsCompliance",
    title: "Ethics Compliance"
  },
  EthicsSupervisor: {
    icon: "zilker-icon zi-Ethics-Supervisor",
    localeDescription: "siginin.ethicsSupervisorDescription",
    description:
      "I am the Ethics supervisor (or equivalent position) for Zilker Programme in our organization and have been asked to complete their ethics form.",
    localeTitle: "signin.ethicsSupervisor",
    title: "Ethics Supervisor"
  },
  EthicsOfficer: {
    icon: "zilker-icon zi-Ethics-Officer",
    localeTitle: "signin.ethicsOfficer",
    localeDescription: "siginin.ethicsOfficerDescription",
    title: "Ethics Officer",
    description:
      "I am the Ethics officer (or equivalent position) for Zilker Programme in our organization and have been asked to complete their ethics form."
  },
  QuestionnaireManagement: {
    icon: "Ziext-Questionnaire",
    accessHeading: "Zilker.home.card1.heading",
    accessDescription: "Zilker.home.card1.description",
    accessContinue: "Zilker.home.card1.continue",
    localeTitle: "zilker.home.questionnaireManagement",
    localeDescription: "zilker.home.questionnaireManagementDescription",
    title: "Questionnaire Management",
    description:
      "I am Author, Approver, Translation Admin or SuperAdmin and i want to manage Questionnaire / Application activities",
    role: "SuperAdmin"
  },
  EthicsAdmin: {
    icon: "zi-Ethics-Admin",
    accessHeading: "Zilker.home.card2.heading",
    accessDescription: "Zilker.home.card2.description",
    accessContinue: "Zilker.home.card2.continue",
    localeTitle: "zilker.home.ethicsAdmin",
    localeDescription: "zilker.home.ethicsAdminDescription",
    title: "Ethics Admin",
    description:
      "I am an Ethics admin and want to oversee the Ethics complaince submissions by Ethics members and take necessary actions",
    role: ["Ethics_Admin", "EthicsAdmin"]
  },
  Reviewer: {
    icon: "zi-Reviewer",
    accessHeading: "Zilker.home.card3.heading",
    accessDescription: "Zilker.home.card3.description",
    accessContinue: "Zilker.home.card3.continue",
    hrefLink: "/reviews/reviewer",
    localeTitle: "zilker.home.reviewer",
    localeDescription: "zilker.home.reviewerDescription",
    title: "Reviewer",
    description:
      "I am reviewer and i want to review the submissions aassigned to me",
    role: "Internal_Reviewer"
  },
  ReviewAdmin: {
    icon: "zi-Review-Admin",
    accessHeading: "Zilker.home.card4.heading",
    accessDescription: "Zilker.home.card4.description",
    accessContinue: "Zilker.home.card4.continue",
    hrefLink: "/reviews/admin",
    localeTitle: "zilker.home.reviewerAdmin",
    localeDescription: "zilker.home.reviewerAdminDescription",
    title: "Review Admin",
    description:
      "I am review admin and i want to review the submissions and also manage the review related activities",
    role: "Review_Admin"
  }
};
export {
  roleCardDetailsInternal,
  roleCardDetailsExternal,
  userRolesCard,
  userRolesCardLocale
};
