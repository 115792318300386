import React, { useState } from "react";
import PropTypes from "prop-types";

const TextArea = props => {
  const { id, className, defaultValue, isError, errorLabel } = props;
  const {
    callback,
    isresize,
    label,
    ariaLabelledBy,
    paraLabelLength,
    ...rest
  } = props;
  const [textEntered, setTextEntered] = useState(
    defaultValue.length > 0 ? "form-textbox-entered" : ""
  );
  const [focus, setFocus] = useState(false);

  const onFocusEvent = e => {
    setTextEntered("form-textbox-entered");
    callback && callback(e);
    setFocus(true);
  };
  const onBlurEvent = e => {
    setTextEntered(e.target.value.length > 0 ? "form-textbox-entered" : "");
    callback && callback(e);
    setFocus(false);
  };
  return (
    <div
      className={`form-textbox ${className} ${isError ? "is-error" : ""}`}
      style={{
        height: `${paraLabelLength && paraLabelLength.textareaHeight}rem`
      }}
    >
      <div
        className={`form-textarea ${textEntered} ${focus ? "focused" : ""}`}
        style={{
          height: `${paraLabelLength && paraLabelLength.textboxformHeight}rem`
        }}
      >
        <textarea
          {...rest}
          {...(isresize && { style: { resize: "none" } })}
          defaultValue={defaultValue}
          onFocus={onFocusEvent}
          onBlur={onBlurEvent}
          aria-labelledby={ariaLabelledBy}
          style={{
            paddingTop: `${paraLabelLength &&
              paraLabelLength.textareaPadding}rem`
          }}
        />
      </div>
      <span
        className="form-textbox-label"
        id={`textarea-placeholder-${id}`}
        aria-hidden="true"
      >
        {label}
      </span>
      {isError && (
        <div className="form-message-wrapper" id={`form-message-${id}`}>
          <span className="visuallyhidden">Error:</span>
          <span className="form-message">{errorLabel}</span>
        </div>
      )}
    </div>
  );
};

TextArea.propTypes = {
  isresize: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  callback: PropTypes.func,
  isError: PropTypes.bool,
  paraLabelLength: PropTypes.any,
  ariaLabelledBy: PropTypes.string,
  errorLabel: PropTypes.string
};

TextArea.defaultProps = {
  isresize: true,
  isError: false
};
export default TextArea;
