import React, { Component, Suspense } from "react";
import { AppContext } from "context/AppContext";
import ErrorCatchComponent from "components/common/ErrorCatchComponent";
import MenuComponent from "components/Menu";
import LoaderUI from "components/LoaderUI/LoaderUI";
import SnackBar from "components/SnackBar";
import { IntlProvider, addLocaleData } from "react-intl";
import CoreLayout from "templates/layouts";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "routes";
import PropTypes from "prop-types";
import defaultLocale from "messages/translation";
import "./App.scss";

const locale = navigator.language.split(/[-_]/)[0];
(async () => {
  const localeData = await import(`react-intl/locale-data/${locale}`);
  addLocaleData(localeData.default);
})();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSnackbar: false,
      snackbarData: {},
      messages: defaultLocale,
      isLocaleLoaded: false,
      appContextValue: {
        showLoader: this.showLoader,
        showSnackBar: this.showSnackBar,
        setLocaleData: this.setLocaleData
      }
    };
  }

  showSnackBar = async data => {
    await this.setState(
      {
        showSnackbar: true,
        snackbarData: data
      },
      () => {
        setTimeout(
          () =>
            this.setState({
              showSnackbar: false,
              snackbarData: {}
            }),
          10000
        );
      }
    );
  };

  setLocaleData = async (localeResponse, isNavBar = true) => {
    const { messages } = this.state;
    if (localeResponse === undefined) {
      await this.setState({
        messages: messages,
        isLocaleLoaded: isNavBar
      });
    } else {
      await this.setState({
        messages: { ...messages, ...localeResponse },
        isLocaleLoaded: isNavBar
      });
    }
  };
  render() {
    const {
      props: { history },
      state: {
        appContextValue,
        showSnackbar,
        snackbarData,
        messages,
        isLocaleLoaded
      }
    } = this;
    const pathname =
      window.location.pathname === "/"
        ? "landing"
        : window.location.pathname.substring(1);
    return (
      <ErrorCatchComponent>
        <IntlProvider locale={"en"} messages={messages}>
          <AppContext.Provider value={appContextValue}>
            <div className={`app-container`}>
              <div className={`applicantsPage ${pathname}`}>
                <Router history={history}>
                  <Suspense
                    fallback={
                      <>
                        <LoaderUI />
                      </>
                    }
                  >
                    {isLocaleLoaded && <MenuComponent />}
                    {showSnackbar && (
                      <SnackBar
                        content={snackbarData.content}
                        type={snackbarData.type}
                      />
                    )}
                    <CoreLayout>{Routes}</CoreLayout>
                  </Suspense>
                </Router>
              </div>
            </div>
          </AppContext.Provider>
        </IntlProvider>
      </ErrorCatchComponent>
    );
  }
}

App.propTypes = {
  history: PropTypes.object
};

export default App;
