import React from "react";
import { FormattedHTMLMessage } from "react-intl";

export const setNewLineContent = text => {
  let newText =
    text &&
    text.split("<br>").map(item => {
      return (
        <p key={item} className="set-new-line-content">
          {item}
        </p>
      );
    });
  return newText;
};

export const setLocale = value => {
  //value.includes("-") && value.length === 5
  const lanCode = !!value;
  if (lanCode) {
    return value;
  }
  return "en-US"; // if value null, undefined, blank, 0 and false
};

export const getMessages = (messageObject, key) => {
  return messageObject[key] && messageObject[key].id ? (
    <FormattedHTMLMessage {...messageObject[key]} />
  ) : (
    ""
  );
};

export const getUserData = () => {
  if (window.localStorage) {
    if (localStorage.getItem("checkUserResponse")) {
      return JSON.parse(localStorage.getItem("checkUserResponse"));
    }
    return null;
  }
  return null;
};

export const getCurrentYear = () => {
  return new Date().getFullYear();
};
