import React from "react";
import "./LoaderUI.scss";
class LoaderUI extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  //added
  render() {
    return (
      <div className="loading-shell">
        <div className="custom-loading-spinner" />
      </div>
    );
  }
}

export default LoaderUI;
