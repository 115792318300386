import React, { Component } from "react";
import PropTypes from "prop-types";
import getLinkType from "../getLinkType";
import { APP_CONSTANT } from "Constants/appConstants";
import { getUserData } from "services/utils";

class LocalNav extends Component {
  static propTypes = {
    as: PropTypes.elementType
  };
  constructor(props) {
    super(props);
    this.userData = getUserData();
    this.state = {
      currentItem: props.selectedItem
    };
    // this.prefixTitle ='My';
  }

  handleSelectedItem = item => {
    this.setState({
      currentItem: item.value
    });
  };

  getTitle = () => {
    const {
      props: { menuItems, pageTitle, prefixTitle },
      state: { currentItem }
    } = this;

    return menuItems && menuItems.length > 0
      ? `${prefixTitle ? prefixTitle : ""} ${currentItem} `
      : pageTitle;
  };

  getUsername = () => {
    if (this.userData === null || Object.keys(this.userData).length === 0)
      return "";
    if (
      this.userData.applicationType === APP_CONSTANT.APPLICATON_TYPE.EXTERNAL
    ) {
      const name =
        this.userData && this.userData.personFirstName
          ? `${this.userData.personFirstName} ${
              this.userData.personLastName ? this.userData.personLastName : ""
            }`
          : "No user";
      return name;
    } else {
      const name =
        Object.keys(this.userData).length > 0 && this.userData.firstName // was before personFirstName
          ? `${this.userData.firstName} ${
              this.userData.lastName ? this.userData.lastName : ""
            }`
          : "No user";
      return name;
    }
  };

  splitName = name => {
    return name.split("")[0];
  };

  getUserThumbnail = () => {
    if (this.userData === null || Object.keys(this.userData).length === 0)
      return "";
    let userFirstName, userLastName;
    if (
      this.userData.applicationType === APP_CONSTANT.APPLICATON_TYPE.EXTERNAL
    ) {
      let { personFirstName, personLastName } = this.userData;
      userFirstName = personFirstName ? this.splitName(personFirstName) : "";
      userLastName = personLastName ? this.splitName(personLastName) : "";
    } else {
      let { firstName, lastName } = this.userData;
      userFirstName = firstName ? this.splitName(firstName) : "";
      userLastName = lastName ? this.splitName(lastName) : "";
    }
    const name = `${userFirstName}${userLastName}`;
    return name ? name.toUpperCase() : "N";
  };

  render() {
    const {
      props: {
        menuItems,
        as: elementAttr,
        hasMenu,
        signOutUrl,
        showAuthSection,
        classNames,
        signOutBtnText
      },
      state: { currentItem }
    } = this;
    const ElementType = getLinkType(LocalNav, this.props);

    if (!hasMenu) return null;

    return (
      <>
        <input
          type="checkbox"
          id="localnav-menustate"
          className="localnav-menustate"
        />
        <nav
          id="localnav"
          className={`localnav ${classNames}`}
          data-sticky
          role="navigation"
        >
          <div className="localnav-wrapper">
            <div className="localnav-background" />
            <div className="localnav-content">
              <div className="localnav-title">
                <a href="# ">{this.getTitle()}</a>
              </div>
              <div className="localnav-menu">
                <a
                  href="#localnav-menustate"
                  role="button"
                  className="localnav-menucta-anchor localnav-menucta-anchor-open"
                  id="localnav-menustate-open"
                >
                  <span className="localnav-menucta-anchor-label">
                    Local Nav Open Menu
                  </span>
                </a>
                <a
                  href="# "
                  role="button"
                  className="localnav-menucta-anchor localnav-menucta-anchor-close"
                  id="localnav-menustate-close"
                >
                  <span className="localnav-menucta-anchor-label">
                    Local Nav Close Menu
                  </span>
                </a>
                <div className="localnav-menu-tray">
                  <ul className="localnav-menu-items">
                    {menuItems &&
                      menuItems.map(item => {
                        return (
                          <li
                            className="localnav-menu-item"
                            key={item.label}
                            onClick={() => this.handleSelectedItem(item)}
                          >
                            <ElementType
                              to={
                                elementAttr &&
                                typeof elementAttr === "object" &&
                                `${item.path}`
                              }
                              href={
                                typeof elementAttr !== "object" &&
                                `${item.path}`
                              }
                              className={`localnav-menu-link ${
                                item.value === currentItem ? "current" : ""
                              }`}
                            >
                              {item.label}
                            </ElementType>
                          </li>
                        );
                      })}
                    <li className="localnav-menu-item loggedInInfo">
                      <span className="localnav-menu-link">
                        <span
                          className={
                            this.getUserThumbnail() ? "userThumbnail" : ""
                          }
                        >
                          {this.getUserThumbnail()}
                        </span>{" "}
                        <span title={this.getUsername()} className="userName">
                          {this.getUsername()}
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="localnav-actions localnav-actions-center">
                  {showAuthSection && (
                    <div className="localnav-action localnav-action-button">
                      <a
                        href="# "
                        className="localnav-button button button-reduced "
                        onClick={signOutUrl}
                      >
                        {signOutBtnText}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </>
    );
  }
}
LocalNav.propTypes = {
  selectedItem: PropTypes.string,
  signOutUrl: PropTypes.func,
  pageTitle: PropTypes.string,
  menuItems: PropTypes.array,
  hasMenu: PropTypes.bool,
  showAuthSection: PropTypes.bool,
  classNames: PropTypes.string,
  prefixTitle: PropTypes.string,
  signOutBtnText: PropTypes.string
};
LocalNav.defaultProps = {
  hasMenu: true,
  showAuthSection: true,
  menuItems: [],
  classNames: ""
};
export default LocalNav;
